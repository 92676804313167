import { del, get, headers, put } from '../lib/fetch'
import isFuture from 'date-fns/is_future'
import _isEmpty from 'lodash/isEmpty'
import _cloneDeep from 'lodash/cloneDeep'
import _parse from 'date-fns/parse'
import _format from 'date-fns/format'
import _isTomorrow from 'date-fns/is_tomorrow'
import _isToday from 'date-fns/is_today'
import _addMins from 'date-fns/add_minutes'
import { useRef, useEffect } from 'react'
import getConfig from 'next/config'
import { CTA_LOCATION_NAME } from './amplitude'
import SessionStorageService from '../utils/SessionStorageService'
import { getAllConfig } from '../utils/configService'

const {
  publicRuntimeConfig: { PAYMENT_API_URL },
} = getConfig()

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const keys = [32, 33, 34, 35, 36, 37, 38, 39, 40]

const preventDefault = e => {
  e = e || window.event
  if (e.preventDefault) {
    e.preventDefault()
  }
  e.returnValue = false
}

const preventDefaultForScrollKeys = e => {
  if (keys.includes(e.keyCode)) {
    preventDefault(e)
    return false
  }
  return true
}

const disableScrollEvent = () => {
  if (typeof window === 'undefined') {
    return
  }

  document.body.addEventListener('wheel', preventDefault, { passive: false })
  document.body.onkeydown = preventDefaultForScrollKeys
}

const enableScrollEvent = () => {
  if (typeof window === 'undefined') {
    return
  }

  document.body.removeEventListener('wheel', preventDefault, {
    passive: false,
  })
  document.body.onkeydown = null
}

const formatPhone = phone => {
  if (phone && phone.length === 11 && phone.includes('+65')) {
    phone = phone.replace('+65', '')
  }
  return phone
}

const formatAddress = ({ address, floor, unit }) => {
  return address
    ? `${address.trim()}${floor && unit && `, #${floor}-${unit}`}`
    : ''
}

const parseCorrectNumber = value => {
  return Number(value) === 'NaN' ? value : Number(value)
}

const getSimilarAddresses = (currentFormData, userAddresses) => {
  const similarAddresses = []

  const keyinUnit = parseCorrectNumber(currentFormData.unit)
  const keyinFloor = parseCorrectNumber(currentFormData.floor)

  for (let i = 0; i < userAddresses.length; i++) {
    const userAddress = userAddresses[i]

    if (userAddress.type !== currentFormData.type) {
      continue
    }

    const addressUnit = parseCorrectNumber(userAddress.metaData.Unit)
    const addressFloor = parseCorrectNumber(userAddress.metaData.Floor)

    const isEqualPhoneAndName =
      formatPhone(currentFormData.contactPhone) ===
        formatPhone(userAddress.metaData.Phone) &&
      String(currentFormData.pincode) === String(userAddress.pincode)

    if (!isEqualPhoneAndName) continue

    //input floor and unit as well as addresses have floor and unit, compare all values
    if (keyinUnit === addressUnit && keyinFloor === addressFloor) {
      similarAddresses.push(userAddress)
    }

    //no input floor and unit but addresses have floor or unit
    if (!keyinFloor && !keyinUnit && (addressFloor || addressUnit)) {
      similarAddresses.push(userAddress)
    }

    //input floor or unit but addresses don't have floor or unit
    if (!addressFloor && !addressUnit && (keyinFloor || keyinUnit)) {
      similarAddresses.push(userAddress)
    }

    //no input floor and unit as well as addresses don't have floor and unit
    if (!keyinUnit && !addressUnit && !keyinFloor && !addressFloor) {
      similarAddresses.push(userAddress)
    }
  }
  return similarAddresses
}

const disablePageScroll = (disableScroll = true) => {
  document.body.style.overflow = disableScroll ? 'hidden' : 'auto'
  document.body.style['-ms-overflow-style'] = disableScroll ? 'none' : 'auto'
}

const getCards = async ctx => {
  const cardResponse = await get(`${PAYMENT_API_URL}/card`, {
    headers: headers(ctx),
  })
  return { cards: cardResponse.data.card }
}

const setDefaultCard = async cardId => {
  const updateResponse = await put(`${PAYMENT_API_URL}/card/${cardId}`, {
    headers: headers(),
    body: JSON.stringify({ default: true }),
  })
  return { cards: updateResponse.data.card }
}

const deleteCard = cardId => {
  return del(`${PAYMENT_API_URL}/card/${cardId}`, {
    headers: headers(),
  })
}

const getDefaultCard = async userCards => {
  if (!userCards) {
    try {
      const response = await getCards()
      userCards = response.cards
    } catch {
      userCards = []
    }
  }

  let cardValue = null

  if (userCards && userCards.length) {
    const validCards = userCards.filter(card =>
      isFuture(
        new Date(parseInt(card.expiryYear), parseInt(card.expiryMonth), 1)
      )
    )
    cardValue = validCards && validCards.find(card => card.default)

    if (!cardValue && validCards && validCards.length >= 1) {
      cardValue = validCards[0]
    }
  }

  return cardValue
}

const mapRemoteResponseToLocalAddress = addressData => {
  if (_isEmpty(addressData)) {
    return {}
  }

  let newAddressData = {
    ...addressData,
  }
  if (!newAddressData.metaData) {
    newAddressData = {
      ...newAddressData,
      metaData: {},
    }
  }

  const {
    address,
    id,
    pincode,
    metaData: {
      BuildingName: building,
      FirstName: contactName,
      Phone: contactPhone,
      Floor: floor,
      Unit: unit,
    },
    latitude,
    longitude,
    city,
    companyName,
    type,
  } = newAddressData

  return {
    address,
    building,
    city,
    contactName,
    contactPhone,
    floor,
    pincode,
    unit,
    id,
    companyName,
    type,
    location: {
      latitude,
      longitude,
    },
    latitude,
    longitude,
    metaData: newAddressData.metaData,
  }
}

const mapLocalAddressToRemoteQuery = deliveryDetails => {
  const address =
    deliveryDetails.address &&
    deliveryDetails.address.split('Singapore')[0].trim()
  const pattern = /,$/

  return {
    address: address && address.replace(pattern, ''),
    pincode: deliveryDetails.pincode,
    type: deliveryDetails.type,
    companyName: deliveryDetails.companyName,
    metaData: {
      BuildingName: deliveryDetails.building,
      FirstName: deliveryDetails.contactName,
      Floor: deliveryDetails.floor,
      Phone: deliveryDetails.contactPhone,
      Unit: deliveryDetails.unit,
    },
    // This is a hard coded value to be sent on every
    // remote add address query.
    city: 'Singapore',
    latitude: deliveryDetails.location
      ? deliveryDetails.location.latitude
      : deliveryDetails.latitude,
    longitude: deliveryDetails.location
      ? deliveryDetails.location.longitude
      : deliveryDetails.longitude,
  }
}

function calculateDistance(currentLocation, marker) {
  if (!currentLocation) {
    return 0
  }

  return Math.sqrt(
    Math.pow(currentLocation.lat - marker.lat, 2) +
      Math.pow(currentLocation.lng - marker.long, 2)
  )
}

function parseDistanceToText(distance) {
  const meterMapScale = 1000
  //distance is under 1km
  return distance < 1
    ? `${Math.floor(distance * meterMapScale)}m`
    : `${distance.toFixed(1)}km`
}

const mergeSubstitutionItems = order => {
  const clonedOrder = _cloneDeep(order)
  const items = order.items
  const length = items.length
  for (let i = 0; i < length; i++) {
    const item = items[i]
    if (!item.orderDetails.substitutedItemId) {
      continue
    }

    const substitutedItemIndex = items.findIndex(
      x => x.orderDetails.orderItemId === item.orderDetails.substitutedItemId
    )
    items[substitutedItemIndex].orderDetails.substitutionItem = item
  }
  clonedOrder.items = items
  return clonedOrder
}

const sortByName = items => {
  const compareName = (first, second) => {
    var nameA = first.name.toUpperCase()
    var nameB = second.name.toUpperCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  }

  return items.sort(compareName)
}

const roundLinkPoints = points => {
  const convertedPoints = Number(points)

  if (convertedPoints % 1 === 0) {
    return parseInt(convertedPoints)
  }
  return convertedPoints.toFixed(2)
}

const findFreeProducts = (items = []) => {
  const freeItems = items.filter(
    item => item.isFree && item.offers && item.offers.length
  )
  const otherOfferItems = items.filter(
    item => item.offers && item.offers.length && item.mrp && !item.isFree
  )

  const isIncluded = (id, arr = []) => {
    const index = arr.findIndex(item => item && item.id === id)
    return index > -1
  }

  return freeItems.map(item => {
    return {
      ...item,
      productsBought: otherOfferItems
        .filter(
          ({ offers }) =>
            offers && item.offers && isIncluded(item.offers[0].id, offers)
        )
        .map(({ q, mrp, discount, product }) => {
          return Array(Number(q)).fill({ ...product, mrp, discount })
        })
        .reduce((acc, p) => acc.concat(p), []),
    }
  })
}

const checkEveryItemUnselected = items => {
  return (
    _isEmpty(items) || Object.keys(items).every(key => !items[key].isChecked)
  )
}

const getSelectedSellersBySelectedItems = sellersItems => {
  return (sellersItems || []).filter(sellersItem =>
    Object.keys(sellersItem.items).some(
      itemKey =>
        sellersItem.items[itemKey].isChecked &&
        !sellersItem.items[itemKey].isFree
    )
  )
}

const formatTimeSlot = ({ startTime, endTime }) =>
  [
    _isToday(startTime)
      ? 'Today'
      : _isTomorrow(startTime)
      ? 'Tomorrow'
      : _format(startTime, 'D MMM'),
    [
      _format(startTime, 'ha').toLocaleLowerCase(),
      _format(endTime, 'ha').toLocaleLowerCase(),
    ].join('-'),
  ].join(', ')

const toAbsoluteSgTime = time => {
  return _addMins(_addMins(_parse(time), new Date().getTimezoneOffset()), 480)
}
const formatTimeSlotToAbsoluteSGTime = ({
  startTime: _startTime,
  endTime: _endTime,
}) => {
  const startTime = toAbsoluteSgTime(_startTime)
  const endTime = toAbsoluteSgTime(_endTime)
  return [
    _isToday(startTime)
      ? 'Today'
      : _isTomorrow(startTime)
      ? 'Tomorrow'
      : _format(startTime, 'D MMM'),
    [
      _format(startTime, 'ha').toLocaleLowerCase(),
      _format(endTime, 'ha').toLocaleLowerCase(),
    ].join('-'),
  ].join(', ')
}

// format i.e. Tuesday, 22 Feb 2022, 10:00AM - 12:00PM
const formatAmplitudeTimeslot = ({ startTime, endTime }) => {
  const dayOfWeek = _format(startTime, 'dddd')
  const date = _format(startTime, 'D MMM YYYY')
  const start = _format(startTime, 'h:mmA')
  const end = _format(endTime, 'h:mmA')

  return `${dayOfWeek}, ${date}, ${start} - ${end}`
}

// Reference: https://github.com/streamich/react-use/blob/master/src/useClickAway.ts
const useClickAway = (ref, onClickAway, events) => {
  const savedCallback = useRef(onClickAway)
  useEffect(() => {
    savedCallback.current = onClickAway
  }, [onClickAway])
  useEffect(() => {
    const handler = event => {
      const { current: el } = ref
      el && !el.contains(event.target) && savedCallback.current(event)
    }
    for (const eventName of events) {
      document.addEventListener(eventName, handler)
    }
    return () => {
      for (const eventName of events) {
        document.removeEventListener(eventName, handler)
      }
    }
  }, [events, ref])
}

// Reference: https://github.com/streamich/react-use/blob/master/src/usePrevious.ts
const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

function trackRouteHistory(currentRoute) {
  // Set the previous route as the value of the current route
  const previousRoute = SessionStorageService.getItem('currentRoute')
  SessionStorageService.setItem('previousRoute', previousRoute)
  // Set the current route
  SessionStorageService.setItem('currentRoute', currentRoute)
}

function getCtaSublocation() {
  const ctaSublocation = sessionStorage.getItem('ctaSublocation')
  try {
    return JSON.parse(ctaSublocation)
  } catch {
    return null
  }
}

function setCtaSublocation(ctaSublocation) {
  try {
    sessionStorage.setItem('ctaSublocation', JSON.stringify(ctaSublocation))
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

// for add to cart
function getCtaLocation() {
  const ctaLocation = sessionStorage.getItem('ctaLocation')
  if (!ctaLocation) {
    return { pageName: CTA_LOCATION_NAME.ENTRANCE }
  }
  try {
    return JSON.parse(ctaLocation)
  } catch {
    return { pageName: CTA_LOCATION_NAME.ENTRANCE }
  }
}

// for add to cart
function setCtaLocation(ctaLocation) {
  try {
    sessionStorage.setItem('ctaLocation', JSON.stringify(ctaLocation))
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function setCtaLocationMeta(ctaLocationMeta) {
  try {
    sessionStorage.setItem('ctaLocationMeta', JSON.stringify(ctaLocationMeta))
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function getCtaLocationMeta() {
  const ctaLocationMeta = sessionStorage.getItem('ctaLocationMeta')
  if (!ctaLocationMeta) {
    return []
  }
  try {
    return JSON.parse(ctaLocationMeta)
  } catch {
    return []
  }
}

function setPreviousCtaLocationMeta() {
  const ctaLocationMeta = getCtaLocationMeta()
  try {
    sessionStorage.setItem(
      'previousCtaLocationMeta',
      JSON.stringify(ctaLocationMeta)
    )
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function getPreviousCtaLocationMeta() {
  const ctaLocationMeta = sessionStorage.getItem('previousCtaLocationMeta')
  if (!ctaLocationMeta) {
    return []
  }
  try {
    return JSON.parse(ctaLocationMeta)
  } catch {
    return []
  }
}

function setPreviousPageMeta(data) {
  try {
    sessionStorage.setItem('previousPageMeta', JSON.stringify(data))
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function getPreviousPageMeta() {
  const previousPageMeta = sessionStorage.getItem('previousPageMeta')
  if (!previousPageMeta) {
    return {}
  }
  try {
    return JSON.parse(previousPageMeta)
  } catch {
    return {}
  }
}

const paymentSplitsSet = new Set(['fe_pricing_payment_google_pay_3ds_only_web'])

const getPaymentSplits = () => {
  const config = getAllConfig() || {}

  return Object.fromEntries(
    Object.entries(config).filter(([key]) =>
      [...paymentSplitsSet].some(substring => key.includes(substring))
    )
  )
}

const encodeSlugIfExist = slug => {
  return slug ? encodeURIComponent(slug) : undefined
}

const isEncodedURIComponent = str => {
  try {
    return decodeURIComponent(str) !== str
  } catch (e) {
    return false // If decoding fails, it's not encoded
  }
}

export {
  disableScrollEvent,
  enableScrollEvent,
  getSimilarAddresses,
  formatPhone,
  disablePageScroll,
  getCards,
  getDefaultCard,
  mapRemoteResponseToLocalAddress,
  mapLocalAddressToRemoteQuery,
  calculateDistance,
  parseDistanceToText,
  formatAddress,
  mergeSubstitutionItems,
  sortByName,
  roundLinkPoints,
  findFreeProducts,
  checkEveryItemUnselected,
  getSelectedSellersBySelectedItems,
  formatTimeSlot,
  formatTimeSlotToAbsoluteSGTime,
  toAbsoluteSgTime,
  useClickAway,
  usePrevious,
  getCtaSublocation,
  setCtaSublocation,
  getCtaLocation,
  setCtaLocation,
  formatAmplitudeTimeslot,
  setCtaLocationMeta,
  getCtaLocationMeta,
  setPreviousCtaLocationMeta,
  getPreviousCtaLocationMeta,
  trackRouteHistory,
  setPreviousPageMeta,
  getPreviousPageMeta,
  setDefaultCard,
  deleteCard,
  getPaymentSplits,
  encodeSlugIfExist,
  isEncodedURIComponent,
}
