import { GTM_ENV_VAR as GEV } from './eventTrackerFunction'
import compact from 'lodash/compact'
import isFinite from 'lodash/isFinite'
import Router from 'next/router'
import {
  getUtmPayload,
  identifySegment,
  sendAmplitudeEventsToSegment,
  resetSegmentIdentify,
} from './segment'
import { getUserType } from '../utils/userType'
import { getConfigFor } from '../utils/configService'
import { SPLIT_FEATURES } from '../utils/constants'
import SessionStorageService from '../utils/SessionStorageService'

// these are all dummy events not official as our code needs event name to pass through
const DY_EVENT_NAME = {
  PURCHASE: 'purchase',
  ADD_TO_CART_V1: 'add-to-cart-v1',
  PRODUCT_CLICK: 'product-click',
  SEE_ALL_CLICK: 'see-all-click',
  KEYWAORD_SEARCH_V1: 'keyword-search-v1',
}
// amplitude event names
// whitelisted `amplitude_event_name`s on GTEvent to be tracked in amplitude
const AMP_EVENT_NAME = {
  PRODUCT_ADDED_TO_CART: 'product added to cart',
  PRODUCT_REMOVED_FROM_CART: 'product removed from cart',
  PRODUCT_DETAILS_VIEWED: 'product details viewed',
  PRODUCT_ADDED_TO_LIST: 'product added to list',
  PRODUCT_REMOVED_FROM_LIST: 'product removed from list',
  BANNER_CLICKED: 'banner clicked',
  SEARCH_COMPLETED: 'search completed',
  SEARCH_RESULT_PAGE_VIEWED: 'search result page viewed',
  LOGGED_OUT: 'logged out',
  CHECKOUT_DELIVERY_ADDRESS_CONFIRMED: 'checkout delivery address confirmed',
  CHECKOUT_TIMESLOT_CLICKED: 'checkout timeslot clicked',
  CHECKOUT_TIMESLOT_CHANGE_CLICKED: 'checkout timeslot change clicked',
  CHECKOUT_TIMESLOT_CONFIRMED: 'checkout timeslot confirmed',
  CHECKOUT_PAYMENT_METHOD_CONFIRMED: 'checkout payment method confirmed',
  VOUCHER_CODE_ADDED: 'voucher code added',
  LINKPOINT_REDEEM_SELECTED: 'linkpoint redeem selected',
  HOME_CLICKED: 'home clicked',
  CATEGORY_CLICKED: 'category clicked',
  CATEGORY_MAIN_CLICKED: 'category main clicked',
  PROMO_CLICKED: 'promo clicked',
  PROMO_MAIN_CLICKED: 'promo main clicked',
  RECIPE_MAIN_CLICKED: 'recipe main clicked',
  CART_CLICKED: 'cart clicked',
  CHECKOUT_CLICKED: 'cart checkout clicked',
  ORDERS_MAIN_CLICKED: 'orders main clicked',
  STORE_WEEKLY_ADS_CLICKED: 'store weekly ads main clicked',
  BRAND_CLICKED: 'brand clicked',
  BRAND_MAIN_CLICKED: 'brand main clicked',
  WIDGET_SEE_ALL_CLICKED: 'widget see all clicked',
  // account tracking
  USER_LOGIN_SIGNUP_STARTED: 'log in or sign up clicked',
  USER_LOGGED_IN: 'logged in',
  USER_ACCOUNT_CREATED: 'account created',
  CART_SELLER_CHECK_SELECTED: 'cart seller check selected',
  CART_SELLER_UNCHECK_SELECTED: 'cart seller uncheck selected',
  CART_PURCHASE_WITH_PURCHASE_CLICKED: 'cart purchase with purchase clicked',
  CART_PRODUCT_CHECK_SELECTED: 'cart product check selected',
  CART_PRODUCT_UNCHECK_SELECTED: 'cart product uncheck selected',
  EMPTY_CART_SELECTED: 'empty cart selected',
  FILTER_SELECTED: 'filter selected',
  SORT_BY_SELECTED: 'sort by selected',
  OOS_POPUP_VIEW: 'product out of stock popup viewed',
  OOS_POPUP_CLICK: 'product out of stock popup clicked',
  HANDLING_TIME_POPUP_VIEW: 'handling time popup viewed',
  HANDLING_TIME__POPUP_CLICK: 'handling time popup clicked',
  ANNOUNCEMENT_BAR_VIEW: 'announcement bar viewed',
  ANNOUNCEMENT_BAR_MESSAGE: 'message',
  NUDGE_SHOWN: 'nudge_shown',
  NUDGE_SUPPRESSED: 'nudge_suppressed',
  NUDGE_START_CONNECT: 'nudge_start_connect',
  NUDGE_DISMISSED: 'nudge_dismissed',
  SELLER_CLICKED: 'seller clicked',
  RECIPE_DETAIL_LANDED: 'recipe detail landed',
  ALGOLIA_PRODUCT_IMPRESSION: 'product card viewed',
  VIEW_ORDER_CLICKED: 'view order clicked',
  RECIPE_VIDEO_PLAYED: 'video played',
  RECIPE_VIDEO_PAUSED: 'video paused',
  RECIPE_VIDEO_NAVIGATED_AWAY: 'video navigated away',
  RECIPE_VIDEO_COMPLETED: 'video completed',
  ADD_REVIEW_SUBMITTED: 'add review submitted',
  ADD_REVIEW_SELECTED: 'add review selected',
  ORDERS_HISTORY_VIEWED: 'order history page viewed',
  ORDER_SUMMARY_VIEWED: 'order summary page viewed',
  ORDER_DETAILS_VIEWED: 'order details page viewed',
  INVOICE_VIEWED: 'invoice viewed',
  ORDER_CANCELLED: 'order cancelled',
  ADD_ITEMS_CLICKED: 'add items clicked',
  EGIFTING_MAIN_CLICKED: 'egifting main clicked',
  VOUCHER_FILTER_CLICKED: 'voucher filter clicked',
  VOUCHER_SWIMLANE_SWIPED: 'voucher swimlane swiped',
  VOUCHER_EVENT_PROPERTY: 'Event Property',
  VOUCHER_FILTER_SELECTED: 'voucher filter selected',
  HISTORY_VOUCHERS_CLICKED: 'history vouchers clicked',
  VOUCHER_WALLET_SCROLLED: 'voucher wallet scrolled',
  VOUCHER_CODE_ERROR_VIEWED: 'voucher code error viewed',
  SEC_SAVED_CLICKED: 'sec save clicked',
  SEC_VIEW_CLICKED: 'sec voucher details view clicked',
  SELECT_ALL_CLICKED_ON_CART: 'user clicks on select all on cart screen',
  SELECT_ALL_CLICKED_ON_CHECKOUT:
    'user clicks on select all on checkout screen',
  SEC_DOWNLOAD_CLICK: 'sec download clicked',
  SEC_PROMPT_POPUP_VIEWED: 'sec prompt pop up viewed',
  CART_LOADED: 'cart loaded',
  EARLIER_DELIVERY_POPUP_CLICKED: 'earlier delivery popup clicked',
  EARLIER_DELIVERY_POPUP_CONFIRMED: 'earlier delivery popup confirmed',
  DELIVERY_PREFERENCES_POPUP_CONFIRMED: 'delivery preferences popup confirmed',
  ALCOHOL_CHECKBOX_SELECTED: 'alcohol checkbox selected',
  ALCOHOL_ERROR_NOTIFICATION_VIEWED: 'alcohol error notification viewed',
  ALCOHOL_AGE_RESTRICTION_POPUP_VIEWED: 'alcohol age restriction popup viewed',
  ALCOHOL_AGE_RESTRICTION_POPUP_CLICKED:
    'alcohol age restriction popup clicked',
  APP_BROWSER_OPENED: 'app/browser opened',
  CHECKOUT_PLACE_ORDER_CLICKED: 'checkout place order clicked',
  CHECKOUT_AMENDMENT_CLICKED: 'checkout amendment clicked',
  ACC_REC_REVIEW_MODAL_CLICKED: 'review account modal clicked',
  ACC_REC_SUBMIT_SUCCESSFULLY: 'submitted successfully notification viewed',
  VOUCHER_SWIMLANE_VIEWED: 'voucher swimlane viewed',
  VOUCHER_DETAILS_VIEW_CLICKED: 'voucher details view clicked',
  ECOM_VOUCHER_CLICKED: 'ecom voucher clicked',
  HPB_ICON_CLICKED: 'hpb icon clicked',
  ECOM_VOUCHER_CLICK: 'ecom voucher click',
  HPB_INFO_POPUP_CONNECTOR_CLICKED: 'hpb info popup connector clicked',
  ADD_VOUCHER_CLICKED: 'add voucher clicked',
  HPB_CONNECTOR_VIEWED: 'hpb connector viewed',
  ...DY_EVENT_NAME,
}

const DY_EVENTS_PROPERTIES = {
  DY_TYPE: 'dyType',
  VALUE: 'value',
  CURRENCY: 'currency',
  CART: 'cart',
  ITEM_PRICE: 'itemPrice',
  PRODUCT_ID: 'productId',
  QUANTITY: 'quantity',
  DY_ID: 'dyid',
  DY_SESSION: 'dysession',
  VERTICAL: 'vertical',
  CALL_TO_ACTION_LOCATION: 'call to action location',
  DECISION_ID: 'decisionId',
  SLOT_ID: 'slotId',
  TYPE: 'type',
  VARIATIONS: 'variations',
  KEYWORD: 'keywords',
}

const CHECKOUT_STEP_EVENT_NAME = [
  AMP_EVENT_NAME.CHECKOUT_DELIVERY_ADDRESS_CONFIRMED,
  AMP_EVENT_NAME.CHECKOUT_TIMESLOT_CONFIRMED,
  AMP_EVENT_NAME.CHECKOUT_PAYMENT_METHOD_CONFIRMED,
]

const EXCLUDE_LOGIN_SIGNUP_SOURCE_EVENT_NAME = [
  ...CHECKOUT_STEP_EVENT_NAME,
  AMP_EVENT_NAME.VOUCHER_CODE_ADDED,
  AMP_EVENT_NAME.RECIPE_MAIN_CLICKED,
  AMP_EVENT_NAME.OOS_POPUP_CLICK,
  AMP_EVENT_NAME.PROMO_CLICKED,
  AMP_EVENT_NAME.PROMO_MAIN_CLICKED,
  AMP_EVENT_NAME.HOME_CLICKED,
  AMP_EVENT_NAME.HANDLING_TIME__POPUP_CLICK,
  AMP_EVENT_NAME.CATEGORY_MAIN_CLICKED,
  AMP_EVENT_NAME.CATEGORY_CLICKED,
  AMP_EVENT_NAME.CART_SELLER_CHECK_SELECTED,
  AMP_EVENT_NAME.CART_SELLER_UNCHECK_SELECTED,
  AMP_EVENT_NAME.ANNOUNCEMENT_BAR_VIEW,
  AMP_EVENT_NAME.VIEW_ORDER_CLICKED,
  AMP_EVENT_NAME.CART_PURCHASE_WITH_PURCHASE_CLICKED,
  AMP_EVENT_NAME.CART_PRODUCT_CHECK_SELECTED,
  AMP_EVENT_NAME.CART_PRODUCT_UNCHECK_SELECTED,
  AMP_EVENT_NAME.CART_CLICKED,
  AMP_EVENT_NAME.BRAND_CLICKED,
  AMP_EVENT_NAME.BRAND_MAIN_CLICKED,
  AMP_EVENT_NAME.CART_PRODUCT_CHECK_SELECTED,
]

const getVertical = event => {
  return ASSET_TYPE_VERTICAL[event[GEV.ASSET_TYPE]] || null
}

const transformBannerType = bannerType => {
  switch (bannerType) {
    case 'main banners':
      return 'main banner'
    case 'main sub banners':
      return 'child banner'
    case 'highlights':
      return 'sub banner'
    default:
      return 'main banner'
  }
}
const getAmplitudeEventProps = (event, userKey, isSegmentEvent) => {
  // segment common payload {}
  const segmentPayload = isSegmentEvent || {}

  const banner = event[GEV.ECOMMERCE]?.promoClick?.promotions?.[0]
  let bannerType = banner?.id
  let adType = null
  if (banner && banner.id) {
    bannerType = transformBannerType(banner.id)
  }
  if (banner && banner.adId) {
    adType = 'Citrus'
  }
  const productQuantity = Array.isArray(event[GEV.AMPLITUDE_PRODUCT_QUANTITY])
    ? event[GEV.AMPLITUDE_PRODUCT_QUANTITY]
    : isFinite(event[GEV.AMPLITUDE_PRODUCT_QUANTITY])
    ? event[GEV.AMPLITUDE_PRODUCT_QUANTITY]
    : null
  const isSearchCompleteEvent =
    event[GEV.AMPLITUDE_EVENT_NAME] === AMP_EVENT_NAME.SEARCH_COMPLETED
  const searchCompleteEventProps =
    isSearchCompleteEvent && !isSegmentEvent
      ? {
          ...(event[GEV.AMPLITUDE_SEARCH_FEATURE_SELECTED] !== 'campaign' ||
            (event[GEV.AMPLITUDE_SEARCH_FEATURE_SELECTED] !== 'recent' && {
              'user typed search term': event[GEV.SEARCH_TERM],
            })),
          'search term selected':
            event[GEV.KEYWORD_SELECTED] || event[GEV.SEARCH_TERM],
        }
      : {}
  /* istanbul ignore next */
  const shouldNotModify2ClickCTA =
    AMP_EVENT_NAME.PRODUCT_ADDED_TO_CART ||
    AMP_EVENT_NAME.PRODUCT_REMOVED_FROM_CART ||
    AMP_EVENT_NAME.PRODUCT_ADDED_TO_LIST
  const vertical = getVertical(event)

  let fulfilmentType = event[GEV.FUL_CENTER]
  if (
    event[GEV.AMPLITUDE_FULFILMENT_TYPE_IN_ARRAY] &&
    fulfilmentType &&
    !Array.isArray(fulfilmentType)
  ) {
    fulfilmentType = [fulfilmentType]
  }

  const setNullIfSegment = isSegmentEvent ? null : undefined
  const setEmptyArrayIfSegment = isSegmentEvent ? [] : null

  const payload = {
    vertical,
    match_type: event[GEV.MATCH_TYPE],
    'sap store id': event[GEV.LIST_CAT],
    'dbp store id': event[GEV.DBP_STORE_ID],
    'fulfillment type': fulfilmentType,
    'product fulfilment type': event[GEV.AMPLITUDE_PRODUCT_FULFILMENT_TYPE],
    'call to action location': formatCtaLocation(
      event[GEV.AMPLITUDE_CTA_LOCATION],
      vertical
    ),
    'call to action sublocation':
      event[GEV.AMPLITUDE_EVENT_NAME] === AMP_EVENT_NAME.RECIPE_DETAIL_LANDED ||
      event[GEV.AMPLITUDE_EVENT_NAME] === AMP_EVENT_NAME.ADD_REVIEW_SUBMITTED ||
      event[GEV.AMPLITUDE_EVENT_NAME] === AMP_EVENT_NAME.ADD_REVIEW_SELECTED ||
      event[GEV.AMPLITUDE_EVENT_NAME] ===
        AMP_EVENT_NAME.STORE_WEEKLY_ADS_CLICKED ||
      event[GEV.AMPLITUDE_EVENT_NAME] === AMP_EVENT_NAME.RECIPE_MAIN_CLICKED
        ? formatNoVerticalCtaSublocation(
            event[GEV.AMPLITUDE_CTA_SUBLOCATION],
            event[GEV.AMPLITUDE_CTA_SUBLOCATION_SECTION_TYPE]
          )
        : event[GEV.AMPLITUDE_SEARCH_FEATURE_SELECTED] === 'refinement' ||
          event[GEV.AMPLITUDE_EVENT_NAME] === AMP_EVENT_NAME.ADD_VOUCHER_CLICKED
        ? event[GEV.AMPLITUDE_CTA_SUBLOCATION]
        : formatCtaSublocation(
            event[GEV.AMPLITUDE_CTA_SUBLOCATION],
            event[GEV.AMPLITUDE_CTA_SUBLOCATION_SECTION_TYPE]
          ),
    /* istanbul ignore next */
    '2click call to action location':
      shouldNotModify2ClickCTA &&
      typeof event[GEV.AMPLITUDE_2CLICK_CALL_TO_ACTION_LOCATION] === 'string'
        ? event[GEV.AMPLITUDE_2CLICK_CALL_TO_ACTION_LOCATION]
        : format2clickCtaLocation(
            event[GEV.AMPLITUDE_2CLICK_CALL_TO_ACTION_LOCATION]
          ),
    'product rank': event[GEV.PRODUCT_POSITION],
    'sap product id': event[GEV.PROD_ID],
    'dbp product id': event[GEV.PRODUCT_ID_ZOP],
    'product name': event[GEV.PROD_NAME],
    'product names': event[GEV.AMPLITUDE_PRODUCT_NAMES],
    'is past purchase': event[GEV.AMPLITUDE_IS_PAST_PURCHASE],
    'product skus': event[GEV.AMPLITUDE_LIST_CATS],
    'product l1 category': event[GEV.PROD_CAT_L],
    'product quantities': event[GEV.AMPLITUDE_PRODUCT_QUANTITIES],
    'search feature selected': event[GEV.AMPLITUDE_SEARCH_FEATURE_SELECTED],
    'item position': event[GEV.AMPLITUDE_ITEM_POSITIONS],
    'search features count': event[GEV.SEARCH_FEATURES_COUNT],
    'user typed search term': event[GEV.AMPLITUDE_USER_TYPED_SEARCH_TERM]
      ? event[GEV.AMPLITUDE_USER_TYPED_SEARCH_TERM]
      : setNullIfSegment,
    'final order amount': event[GEV.AMPLITUDE_FINAL_ORDER_VALUE],
    'final payment amount': event[GEV.AMPLITUDE_FINAL_PAYMENT_VALUE],
    'product l2 category': event[GEV.PROD_CAT_L1],
    'product l3 category': event[GEV.PROD_CAT_L2],
    'number of promotions': event[GEV.PROMOTION_COUNT],
    'product promotion type': event[GEV.PROMOTION_TYPES],
    'product promotion description': event[GEV.PROMOTION_DESCRIPTIONS],
    'product retail price': event[GEV.RETAIL_PRICE], // original price
    'product selling price': event[GEV.OFFER_PRICE], // discounted price, if no discount then empty value
    'product quantity': productQuantity,
    rating: event[GEV.PRODUCT_RATING],
    'banner name': banner?.name || event[GEV.AMPLITUDE_BANNER_NAME],
    'banner position': banner?.position || event[GEV.AMPLITUDE_BANNER_POSITION],
    'banner type': bannerType || event[GEV.AMPLITUDE_BANNER_TYPE],
    'search suggested keywords': event[GEV.SUGGESTED_KEYWORD_IMPRESSION]
      ? event[GEV.SUGGESTED_KEYWORD_IMPRESSION]
      : null,
    'search suggested campaigns': event[GEV.SUGGESTED_CAMPAIGN_IMPRESSION]
      ? event[GEV.SUGGESTED_CAMPAIGN_IMPRESSION].split(',')
      : setEmptyArrayIfSegment,
    'promo code': event[GEV.AMPLITUDE_PROMO_CODE],
    'total cart value': event[GEV.AMPLITUDE_TOTAL_CART_VALUE],
    'delivery timeslot': event[GEV.AMPLITUDE_DELIVERY_TIMESLOT],
    'linkpoint redeem option': event[GEV.AMPLITUDE_REDEEM_LINKPOINT_OPTION],
    'slots availability status': event[GEV.AMPLITUDE_SLOTS_AVAILABILITY_STATUS],
    'days to available slots': event[GEV.AMPLITUDE_DAYS_TO_AVAILABLE_SLOTS],
    'hours to available slots': event[GEV.AMPLITUDE_HOURS_TO_AVAILABLE_SLOTS],
    'days to selected slot': event[GEV.AMPLITUDE_DAYS_TO_SELECTED_SLOT],
    'hours to selected slot': event[GEV.AMPLITUDE_HOURS_TO_SELECTED_SLOT],
    'category name': event[GEV.AMPLITUDE_CATEGORY_NAME],
    'category level': event[GEV.AMPLITUDE_CATEGORY_LEVEL],
    'vendor id': event[GEV.AMPLITUDE_VENDOR_ID],
    'vendor name': event[GEV.AMPLITUDE_VENDOR_NAME],
    'sort by category': event[GEV.AMPLITUDE_SORT_BY_CATEGORY],
    'filter category': event[GEV.AMPLITUDE_FILTER_CATEGORY],
    'filter details': event[GEV.AMPLITUDE_FILTER_DETAILS],
    'login/signup source': EXCLUDE_LOGIN_SIGNUP_SOURCE_EVENT_NAME.includes(
      event[GEV.AMPLITUDE_EVENT_NAME]
    )
      ? null
      : event[GEV.AMPLITUDE_USER_LOGIN_SIGNUP_SOURCE] || vertical,
    'payment option': event[GEV.AMPLITUDE_PAYMENT_OPTION] || null,
    'voucher code': event[GEV.AMPLITUDE_VOUCHER_CODE],
    'voucher type': event[GEV.AMPLITUDE_VOUCHER_TYPE],
    'number of days': event[GEV.AMPLITUDE_HANDLING_TIME_COUNT],
    'sponsored ad': adType,
    'service fee': event[GEV.AMPLITUDE_SERVICE_FEE],
    'delivery fee': event[GEV.AMPLITUDE_DELIVERY_FEE],
    'linkpoint redemption amount': event[GEV.AMPLITUDE_LINKPOINT_REDEMPTION],
    'order promotion amount': event[GEV.AMPLITUDE_ORDER_PROMOTION_AMOUNT],
    message: event[GEV.AMPLITUDE_MESSAGE],
    'recipe title': event[GEV.AMPLITUDE_RECIPE_TITLE],
    'recipe id': event[GEV.AMPLITUDE_RECIPE_ID],
    'recipe category': event[GEV.AMPLITUDE_RECIPE_CATEGORY],
    'preparation time': event[GEV.AMPLITUDE_RECIPE_PREPARATION_TIME],
    'cooking time': event[GEV.AMPLITUDE_RECIPE_COOKING_TIME],
    'cooking level': event[GEV.AMPLITUDE_RECIPE_COOKING_LEVEL],
    'serving size': event[GEV.AMPLITUDE_RECIPE_SERVING_SIZE],
    'number of ingredients': event[GEV.AMPLITUDE_RECIPE_NUMBER_OF_INGREDIENTS],
    'number of steps': event[GEV.AMPLITUDE_RECIPE_NUMBER_OF_STEPS],
    'media type': event[GEV.AMPLITUDE_RECIPE_MEDIA_TYPE],
    'average rating': event[GEV.AMPLITUDE_RECIPE_AVERAGE_RATING],
    'rating count': event[GEV.AMPLITUDE_RECIPE_RATING_COUNT],
    'step number': event[GEV.AMPLITUDE_RECIPE_STEP_NUMBER],
    'video duration': event[GEV.AMPLITUDE_RECIPE_VIDEO_DURATION]
      ? Math.round(event[GEV.AMPLITUDE_RECIPE_VIDEO_DURATION])
      : null,
    'percentage watched': event[GEV.AMPLITUDE_PERCENTAGE_WATCHED],
    'star rating': event[GEV.AMPLITUDE_RECIPE_REVIEW_RATING],
    'display name': event[GEV.AMPLITUDE_RECIPE_REVIEW_DISPLAY_NAME],
    'review title': event[GEV.AMPLITUDE_RECIPE_REVIEW_TITLE],
    'review text': event[GEV.AMPLITUDE_RECIPE_REVIEW_TEXT],
    'recommended attribute':
      event[GEV.AMPLITUDE_RECIPE_REVIEW_RECOMMENDED_ATTRIBUTE],
    'easy to follow attribute':
      event[GEV.AMPLITUDE_RECIPE_REVIEW_EASY_TO_FOLLOW_ATTRIBUTE],
    'number of active orders': event[GEV.AMPLITUDE_NUMBER_OF_ACTIVE_ORDERS],
    'delivery order id': event[GEV.AMPLITUDE_DELIVERY_ORDER_ID],
    'sales order id': event[GEV.AMPLITUDE_SALES_ORDER_ID],
    'amendable order': event[GEV.AMPLITUDE_AMENDABLE_ORDER],
    'cancellable order': event[GEV.AMPLITUDE_CANCELLABLE_ORDER],
    'order amount': event[GEV.AMPLITUDE_ORDER_AMOUNT],
    'ordered quantity': event[GEV.AMPLITUDE_ORDER_QUANTITY],
    'store id': event[GEV.AMPLITUDE_STORE_ID],
    'hours to delivery timeslot':
      event[GEV.AMPLITUDE_HOURS_TO_DELIVERY_TIMESLOT],
    'days to delivery timeslot': event[GEV.AMPLITUDE_DAYS_TO_DELIVERY_TIMESLOT],
    'delivery order status': event[GEV.AMPLITUDE_DELIVERY_ORDER_STATUS],
    'sales order status': event[GEV.AMPLITUDE_SALES_ORDER_STATUS],
    'order type': event[GEV.AMPLITUDE_ORDER_TYPE],
    'total product count': event[GEV.TOTAL_PRODUCT_COUNT],
    'search term selected': event[GEV.AMPLITUDE_SEARCH_TERM_SELECTED],
    'first available slot time':
      event[GEV.AMPLITUDE_TIMESLOT_AVAILABLE_SLOT_TIME],
    'current time of slot selection':
      event[GEV.AMPLITUDE_TIMESLOT_CURRENT_TIME],
    'member type': event[GEV.MEMBER_TYPE],
    'product hours to available slot':
      event[GEV.AMPLITUDE_PRODUCT_HOURS_TO_AVAILABLE_SLOT],
    'product days to available slot':
      event[GEV.AMPLITUDE_PRODUCT_DAYS_TO_AVAILABLE_SLOT],
    'mkp item': event[GEV.AMPLITUDE_IS_MKP_ITEMS],
    'potential timeslot shown': event[GEV.AMPLITUDE_POTENTIAL_TIMESLOT_SHOWN],
    'potential hours to available slot':
      event[GEV.AMPLITUDE_POTENTIAL_HOURS_TO_AVAILABLE_SLOT],
    'potential days to available slot':
      event[GEV.AMPLITUDE_POTENTIAL_DAYS_TO_AVAILABLE_SLOT],
    'order promotion type': event[GEV.AMPLITUDE_ORDER_PROMOTION_TYPE],
    'order promotion description':
      event[GEV.AMPLITUDE_ORDER_PROMOTION_DESCRIPTION],
    'earlier delivery option': event[GEV.AMPLITUDE_EARLIER_DELIVERY_OPTION],
    'leave item at doorstep option':
      event[GEV.AMPLITUDE_LEAVE_ITEM_AT_DOORSTEP_OPTION],
    'selected delivery instruction':
      event[GEV.AMPLITUDE_SELECTED_DELIVERY_INSTRUCTION] || setNullIfSegment,
    ...searchCompleteEventProps,
    ...segmentPayload,
    'fairprice payment option':
      event[GEV.SEGMENT_FAIRPRICE_PAYMENT_OPTION] || setNullIfSegment,
    'substitution option': event[GEV.SEGMENT_SUBSTITUTION_OPTION],
    'early delivery option':
      event[GEV.SEGMENT_EARLY_DELIVERY_OPTION] || setNullIfSegment,
    'leave at doorstep option':
      event[GEV.SEGMENT_LEAVE_AT_DOORSTEP_OPTION] || setNullIfSegment,
    'total ordered quantity': event[GEV.SEGMENT_TOTAL_ORDERED_QUANTITY],
    'distance to outlet':
      event[GEV.SEGMENT_DISTANCE_TO_OUTLET] || setNullIfSegment,
    'delivery': event[GEV.SEGMENT_DELIVERY],
    'order offer': event[GEV.SEGMENT_ORDER_OFFER],
    'products': event[GEV.SEGMENT_PRODUCT],
    'screen category': event[GEV.SEGMENT_SCREEN_CATEGORY],
    'store type': event[GEV.SEGMENT_STORE_TYPE],
    'destination link': event[GEV.AMPLITUDE_BANNER_DESTINATION_LINK],
    'banner id': event[GEV.AMPLITUDE_BANNER_ID],
    'dy_event_properties': event[GEV.AMPLITUDE_DY_EVENT_PROPERTIES],
    'dy_engagement_properties': event[GEV.AMPLITUDE_DY_ENGAGEMENT_PROPERTIES],
    'destinations': event[GEV.AMPLITUDE_DY_DESTINATIONS],
    'dyid': event[GEV.AMPLITUDE_DY_ID],
    'dysession': event[GEV.AMPLITUDE_DY_SESSION],
    'orderType': event[GEV.AMPLITUDE_DY_ORDER_TYPE],
    'sdt': event[GEV.AMPLITUDE_DY_SDT],
    'is voucher available': event[GEV.AMPLITUDE_IS_VOUCHER_AVAILABLE],
    'is voucher shown': event[GEV.AMPLITUDE_IS_VOUCHER_SHOWN],
    'cancel reason': event[GEV.AMPLITUDE_CANCEL_REASON],
    'offer id': event[GEV.AMPLITUDE_VOUCHER_OFFER_ID],
  }
  if (
    event[GEV.AMPLITUDE_SEARCH_QUERY_ID] &&
    (event.amplitude_event_name === 'product added to cart' ||
      event.amplitude_event_name === 'product details viewed' ||
      event.amplitude_event_name === 'product added to list' ||
      event.amplitude_event_name === 'filter selected')
  ) {
    payload['scope'] = event[GEV.AMPLITUDE_EVENT_SCOPE]
    payload['filters'] = event[GEV.AMPLITUDE_FILTERS]
      ? event[GEV.AMPLITUDE_FILTERS]
      : []
    payload['index_name'] = event[GEV.AMPLITUDE_SEARCH_INDEX_NAME]
    payload['query_id'] = event[GEV.AMPLITUDE_SEARCH_QUERY_ID]
    payload['type'] = event[GEV.AMPLITUDE_EVENT_TYPE]
    payload['user_token'] = event[GEV.USER_ID] ? event[GEV.USER_ID] : userKey
    payload['positions'] = [event[GEV.PRODUCT_POSITION]]
  }

  if (
    event[GEV.AMPLITUDE_SEARCH_QUERY_ID] &&
    event.amplitude_event_name === 'product card viewed'
  ) {
    payload['scope'] = event[GEV.AMPLITUDE_EVENT_SCOPE]
    payload['index_name'] = event[GEV.AMPLITUDE_SEARCH_INDEX_NAME]
    payload['query_id'] = event[GEV.AMPLITUDE_SEARCH_QUERY_ID]
    payload['type'] = event[GEV.AMPLITUDE_EVENT_TYPE]
    payload['user_token'] = event[GEV.USER_ID] ? event[GEV.USER_ID] : userKey
  }
  return payload
}

const omniVerticalString = 'omni-vertical'

const GROCERY_ONLINE_VERTICAL_STRING = 'grocery-online'

const ASSET_TYPE_VERTICAL = {
  FPON: GROCERY_ONLINE_VERTICAL_STRING,
  fpon: GROCERY_ONLINE_VERTICAL_STRING,
  'grocery-online': GROCERY_ONLINE_VERTICAL_STRING,
  Lifestyle: 'lifestyle',
  Account: 'account',
  REWARDS: 'rewards',
  OMNI: omniVerticalString,
}

const CTA_LOCATION_NAME = {
  HOME: 'home',
  CART: 'cart',
  PRODUCT_DETAIL: 'product',
  PRODUCT_LISTING: 'product listing',
  RECIPE_DETAIL: 'recipe detail',
  SHOPPING_LIST: 'shopping list',
  CATEGORY: 'category',
  PROMOTION: 'promotion',
  TAG: 'tag',
  SEARCH: 'search',
  BRAND: 'brand',
  ENTRANCE: 'entrance', // first page/screen that the user sees as their first session on our platform
  CHECKOUT: 'checkout',
  ALL: 'all',
  REDIRECT: 'redirect',
  ORDER_HISTORY: 'order history',
  ORDER_SUMMARY: 'order summary',
  ORDER_DETAILS: 'order details',
  VOUCHER_WALLET: 'voucher wallet',
  PROFILE: 'profile',
  ACC_REVIEW_SUCCESS: 'account | account review submitted success',
  WEEKLYPROMOTION: 'weekly promotions',
}

const amplitudeEventNames = Object.values(AMP_EVENT_NAME)
const amplitudeUserPropEventNames = [
  AMP_EVENT_NAME.USER_LOGGED_IN,
  AMP_EVENT_NAME.USER_ACCOUNT_CREATED,
  AMP_EVENT_NAME.LOGGED_OUT,
]

// determines if event should be tracked in amplitude
// if event should be tracked, passes amplitude event
// transformed/derived from GTEvent to callback
const sendGaEventToAmplitude = (event, amplitudeClient, userKey) => {
  // is amplitude ready
  if (!amplitudeClient) {
    return
  }

  const ampEventName = event[GEV.AMPLITUDE_EVENT_NAME]
  // check if segment events comes as well
  const isSegmentEvent = event[GEV.IS_SEGEMENT_EVENT]

  // only proceed if event name is whitelisted
  if (amplitudeEventNames.includes(ampEventName)) {
    // 1. set user properties
    if (amplitudeUserPropEventNames.includes(ampEventName)) {
      const isLoggingOut = ampEventName === AMP_EVENT_NAME.LOGGED_OUT
      const entrySource =
        event[GEV.AMPLITUDE_USER_LOGIN_SIGNUP_SOURCE] || getVertical(event)
      const userProps = {
        'user id': isLoggingOut ? null : event[GEV.USER_ID],
        'account type': isLoggingOut
          ? null
          : event[GEV.AMPLITUDE_USER_ACCOUNT_TYPE],
        'link plus id': isLoggingOut
          ? null
          : event[GEV.AMPLITUDE_USER_LINK_PLUS_ID],
        'linkpass id': isLoggingOut
          ? null
          : event[GEV.AMPLITUDE_USER_LINKPASS_ID],
        'user type': isLoggingOut ? null : event[GEV.AMPLITUDE_LOGIN_USER_TYPE],
        'login/signup source': isLoggingOut ? null : entrySource,
        'experiment': event[GEV.TESTING_VERSION],
      }
      amplitudeClient.default.getInstance().setUserProperties(userProps)

      const { isEnabled, configuration } =
        getConfigFor(SPLIT_FEATURES.FE_SEGMENT_IDENTIFY_CALLS) || {}
      const isIdentifySegmentEnabled = isEnabled && configuration?.is_segment_on

      if (isIdentifySegmentEnabled && isLoggingOut) {
        resetSegmentIdentify()
      }
      if (
        isIdentifySegmentEnabled &&
        ampEventName === AMP_EVENT_NAME.USER_LOGGED_IN
      ) {
        const utmQuery = SessionStorageService.getItem('utm_payload')
        const data = utmQuery ? JSON.parse(utmQuery) : {}
        const utmPayload = getUtmPayload(data)

        const sourceEntryoint =
          event[GEV.AUTH_SIGNUP_ENTRY_SOURCE] || ASSET_TYPE_VERTICAL.FPON
        const userId = userProps['linkpass id']
        const userType = getUserType() || 'b2c'
        const accountType = isLoggingOut
          ? null
          : event[GEV.AMPLITUDE_USER_ACCOUNT_TYPE]
        const traits = {
          ...userProps,
          'ga client id':
            document.cookie.match(/_ga=(.+?);/) &&
            document.cookie.match(/_ga=(.+?);/).length
              ? document.cookie
                  .match(/_ga=(.+?);/)[1]
                  .split('.')
                  .slice(-2)
                  .join('.')
              : null,
          'customer login status': isLoggingOut ? false : true,
          'shopping mode':
            userType && typeof userType === 'string' && userType.toLowerCase(),
          'account type':
            accountType &&
            typeof accountType === 'string' &&
            accountType.toLowerCase(),
          'login/signup source': isLoggingOut ? null : sourceEntryoint,
          'online_fp_id': isLoggingOut ? null : event[GEV.USER_ID],
          'userId': isLoggingOut ? null : event[GEV.AMPLITUDE_USER_LINKPASS_ID],
          'user type': 'existing',
          ...utmPayload,
        }
        // Segment identify method
        identifySegment({ ...traits }, userId)
      }
    }

    if (isSegmentEvent) {
      const segEventProps = getAmplitudeEventProps(
        event,
        userKey,
        isSegmentEvent
      )
      sendAmplitudeEventsToSegment(ampEventName, segEventProps)
    } else {
      // 2. send event with event properties
      const ampEventProps = getAmplitudeEventProps(
        event,
        userKey,
        isSegmentEvent
      )
      amplitudeClient.default
        .getInstance()
        .logEventWithTimestamp(ampEventName, ampEventProps, Date.now())
    }
  }
}

// send events that are already in data layer
// before amplitude client initialized
// to amplitude when the SDK is ready
const pushDataLayerToAmplitude = (amplitudeClient, userKey) => {
  if (!window.dataLayer || !Array.isArray(window.dataLayer)) {
    return
  }
  window.dataLayer.forEach(gaEvent => {
    sendGaEventToAmplitude(gaEvent, amplitudeClient, userKey)
  })
}

const formatSectionNameDefault = name => {
  // remove special characters except space
  const removeSpecialCharRegex = /[^a-zA-Z0-9 ]/g
  const formattedString = name.replace(removeSpecialCharRegex, '')
  // replace all space with dash and convert all to lowercase
  return formattedString.replace(/ /g, '-').toLowerCase()
}

const formatCtaSublocation = (sectionNames, sectionType) => {
  if (!sectionNames || !sectionNames?.length) {
    return null
  }
  const formattedSectionNames = compact(sectionNames).map(sectionName =>
    sectionName.toLowerCase()
  )
  const defaultSectionType =
    formattedSectionNames.length > 1 ? 'modal' : 'widget'
  return `${sectionType || defaultSectionType} | ${formattedSectionNames.join(
    ' | '
  )}`
}

const formatSectionNameKeepSpace = name => {
  // remove special characters except space
  const removeSpecialCharRegex = /[^a-zA-Z0-9 ]/g
  const formattedString = name.replace(removeSpecialCharRegex, '')
  return formattedString.toLowerCase()
}

// support new cta sublocation format, currently required only for recipe detail landed amplitude event
// if more events require this format, we can refactor this to be more generic
const formatNoVerticalCtaSublocation = (sectionNames, sectionType) => {
  const formattedSectionNames = compact(sectionNames).map(
    formatSectionNameKeepSpace
  )
  return `${sectionType || 'widget'} | ${formattedSectionNames.join(' | ')}`
}

const formatCtaLocation = (props, vertical = ASSET_TYPE_VERTICAL.FPON) => {
  if (!props) {
    return null
  }
  const { pageName, pageMeta } = props

  if (props === CTA_LOCATION_NAME.PROFILE && vertical === omniVerticalString) {
    return 'account | all'
  }

  if (
    props === CTA_LOCATION_NAME.ACC_REVIEW_SUCCESS &&
    vertical === omniVerticalString
  ) {
    return CTA_LOCATION_NAME.ACC_REVIEW_SUCCESS
  }

  if (!pageName || pageName === CTA_LOCATION_NAME.ENTRANCE) {
    return `${vertical} | ${CTA_LOCATION_NAME.ENTRANCE}`
  }
  const pageMetaString = compact(pageMeta).join(' | ')

  // For segment account_created handling
  if (pageName === 'account' && vertical === omniVerticalString) {
    return `${pageName}${pageMetaString ? ` | ${pageMetaString}` : ''}`
  }

  // For PDP page voucher click handling
  if (pageName === 'product' && vertical === 'rewards') {
    return `grocery-online | ${pageName}${
      pageMetaString ? ` | ${pageMetaString}` : ''
    }`
  }

  return `${vertical} | ${pageName}${
    pageMetaString ? ` | ${pageMetaString}` : ''
  }`
}

const format2clickCtaLocation = previousPageCtaLocation => {
  return formatCtaLocation(previousPageCtaLocation, ASSET_TYPE_VERTICAL.FPON)
}

const getAmplitudeEvenNameByCheckoutStep = step => {
  const mapping = {
    1: AMP_EVENT_NAME.CHECKOUT_DELIVERY_ADDRESS_CONFIRMED,
    3: AMP_EVENT_NAME.CHECKOUT_PAYMENT_METHOD_CONFIRMED,
  }
  return mapping[step] || null
}

const getListNameByCheckoutStep = step => {
  switch (step) {
    case 1:
      return 'checkout-postal code'
    case 2:
      return 'checkout-time slot'
    case 3:
      return 'checkout-payment type'
    case 4:
      return 'checkout-cart viewed'
    case 5:
      return 'checkout-place order'
    default:
      return null
  }
}

/* istanbul ignore next */
const getPageTypeByUrl = (route = Router.route) => {
  switch (route) {
    case '/':
    case '/recipes':
      return CTA_LOCATION_NAME.HOME
    case '/cart':
      return CTA_LOCATION_NAME.CART
    case '/checkout':
      return CTA_LOCATION_NAME.CHECKOUT
    case '/brandDetails':
    case '/brands':
      return CTA_LOCATION_NAME.BRAND
    case '/categoryDetails':
    case '/categories':
    case '/recipeCategories':
    case '/recipeCategoryDetail':
      return CTA_LOCATION_NAME.CATEGORY
    case '/promotions':
      return CTA_LOCATION_NAME.PROMOTION
    case '/search':
    case '/recipeSearch':
      return CTA_LOCATION_NAME.SEARCH
    case '/product-listing':
      return CTA_LOCATION_NAME.PRODUCT_LISTING
    case '/productDetails':
      return CTA_LOCATION_NAME.PRODUCT_DETAIL
    case '/tagDetails':
      return CTA_LOCATION_NAME.TAG
    case '/shoppinglist':
      return CTA_LOCATION_NAME.SHOPPING_LIST
    case '/recipeDetails':
      return CTA_LOCATION_NAME.RECIPE_DETAIL
    case '/redirect':
      return CTA_LOCATION_NAME.REDIRECT
    case '/orders':
      return CTA_LOCATION_NAME.ORDER_HISTORY
    case '/order-summary':
      return CTA_LOCATION_NAME.ORDER_SUMMARY
    case '/weekly-promotions':
      return CTA_LOCATION_NAME.WEEKLYPROMOTION
    default:
      return null
  }
}

export {
  AMP_EVENT_NAME,
  DY_EVENT_NAME,
  DY_EVENTS_PROPERTIES,
  CTA_LOCATION_NAME,
  pushDataLayerToAmplitude,
  sendGaEventToAmplitude,
  format2clickCtaLocation,
  formatCtaSublocation,
  formatCtaLocation,
  transformBannerType,
  getAmplitudeEventProps,
  getAmplitudeEvenNameByCheckoutStep,
  getPageTypeByUrl,
  formatSectionNameDefault,
  getListNameByCheckoutStep,
}
