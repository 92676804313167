class SplitConfigAdapter {
  unparseValue = value => {
    return {
      treatment: value.isEnabled ? 'on' : 'off',
      config: JSON.stringify(value.config || {}),
    }
  }

  unparseValues = values => {
    return Object.fromEntries(
      Object.entries(values).map(([key, value]) => {
        return [key, this.unparseValue(value)]
      })
    )
  }

  getVariant = rawConfig => {
    return rawConfig?.treatment
  }

  isConfigEnabled = (rawConfig, options) => {
    const enabledFlags = options?.flagOnValue
      ? [options?.flagOnValue]
      : ['on', '1']
    return enabledFlags.includes(rawConfig?.treatment)
  }

  parseConfigProperty = (rawConfig, options) => {
    if (!rawConfig?.config && options?.defaultConfiguration) {
      return options?.defaultConfiguration
    }
    try {
      return JSON.parse(rawConfig?.config || '{}')
    } catch {
      // silent error return empty object
      return options?.defaultConfiguration || {}
    }
  }
}

module.exports = {
  SplitConfigAdapter,
}
