const { SplitConfigAdapter } = require('./SplitConfigAdapter')
const ConfigFallback = require('./fallback')

let globalObject = null

/*
  We are using singelton class pattern to create only one object for ConfigService class.
  We are using this class to store split.io configs
*/
class ConfigService {
  constructor(adapter) {
    this.adapter = adapter
    this.config = this.adapter.unparseValues(ConfigFallback)

    if (!globalObject) {
      globalObject = this
    }
    return globalObject
  }

  setConfigMethod = value => {
    this.config = value
  }

  getAllConfigMethod = () => {
    return this.config || {}
  }

  _setConfigValuesMethod = values => {
    this.config = this.adapter.unparseValues(values)
  }

  getConfigForMethod = (key, options) => {
    const rawConfig = this.config[key]
    const isEnabled = this.adapter.isConfigEnabled(rawConfig, options)
    const variant = this.adapter.getVariant(rawConfig)
    const configuration = this.adapter.parseConfigProperty(rawConfig, options)

    return {
      variant,
      isEnabled,
      configuration,
      raw: rawConfig,
    }
  }
}

const configObject = new ConfigService(new SplitConfigAdapter())

const setConfig = configObject.setConfigMethod

const getAllConfig = configObject.getAllConfigMethod

/**
 * @param key split key name eg, fe_gst_amount
 * @param options options to change default behavior
 * @param options.flagOnValue value of treatment to be considered as enabled eg, given value 'control' when treatment value is 'control' will return isEnabled as true
 * @param options.defaultConfiguration value of config to be used as fallback when there's empty configuration or failed to parse the configuration
 */
const getConfigFor = configObject.getConfigForMethod

const _setConfigValues = configObject._setConfigValuesMethod

module.exports = {
  setConfig,
  getAllConfig,
  getConfigFor,
  _setConfigValues,
}
