import LocalStorageService from '../utils/LocalStorageService'
const safeLowerCase = input => {
  return typeof input === 'string' && typeof input.toLowerCase === 'function'
    ? input.toLowerCase()
    : input
}
export const lowerCaseString = input => {
  if (input === null) {
    return input
  }

  if (Array.isArray(input)) {
    return input.map(data =>
      typeof data === 'object' ? lowerCaseString(data) : safeLowerCase(data)
    )
  }
  const out = {}
  Object.keys(input).forEach(key => {
    if (input[key] !== undefined) {
      out[key] =
        typeof input[key] === 'object'
          ? lowerCaseString(input[key])
          : safeLowerCase(input[key])
    }
  })
  return out
}

export const resetSegmentIdentify = () => {
  // is Segment ready
  if (!window?.analytics) {
    return
  }
  window.analytics?.reset()
}

const sendAmplitudeEventsToSegment = (ampEventName, event) => {
  // is Segment ready
  if (!window?.analytics) {
    return
  }

  let traits = {}
  const userTraits = LocalStorageService.getItem('ajs_user_traits')

  if (userTraits) {
    const data = JSON.parse(userTraits) || {}

    if (Object.keys(data).length) {
      traits = data
    }
  }
  window.analytics?.track(
    ampEventName,
    { ...event },
    { 'online_fp_id': traits?.online_fp_id || null }
  )
}

const identifySegment = (traits, userId) => {
  // is Segment ready
  if (!window?.analytics) {
    return
  }

  if (userId) {
    // used for 'logged in user'
    window.analytics?.identify(userId, { ...traits })
  } else {
    // used for 'non logged in user' as we don't have any userId
    window.analytics?.identify({ ...traits })
  }
}

const removeItemsKeyword = obj => {
  if (!Object.keys(obj).length) {
    return {}
  }

  const result = {}

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = key.replace(' items', '') // Remove ' items' from the property name
      result[newKey] = obj[key]
    }
  }

  return result
}

const includeAllowedProps = (obj, value, isString) => {
  const result = {}

  const combo = {
    viewall: ['campaign', 'autocomplete'],
    viewall_enter: ['campaign', 'autocomplete'],
    campaign: ['trending', 'recent', 'campaign'],
    recent: ['trending', 'recent', 'campaign'],
    trending: ['trending', 'recent', 'campaign'],
    autocomplete: ['campaign', 'autocomplete'],
    category: ['campaign', 'autocomplete', 'category']
  }

  const allowedItem = combo[value] || []

  const data = removeItemsKeyword(obj)

  for (const key in data) {
    if (allowedItem.includes(key)) {
      const response =
        data[key] && typeof data[key] === 'string' && isString
          ? [...data[key].split(',')]
          : []
      result[key] = isString ? response : data[key]
    } else {
      result[key] = isString ? [] : 0
    }
  }
  return result
}

const getCurrentSegmentSessionID = () => {
  const sessionID = LocalStorageService.getItem('analytics_session_id')
  return sessionID
}

const getUtmPayload = (query, flag) => {
  const utmPayload = {
    'fbclid': query?.fbclid || null,
    'gclid': query?.gclid || null,
    'referrer': query?.referrer || null,
    'referring domain': query?.referring_domain || null,
    'utm campaign': query?.utm_campaign || null,
    'utm content': query?.utm_content || null,
    'utm medium': query?.utm_medium || null,
    'utm source': query?.utm_source || null,
    'utm term': query?.utm_term || null,
  }

  if (flag) {
    return {
      'fbclid': query?.fbclid || null,
      'gclid': query?.gclid || null,
      'referrer': query?.referrer || null,
      'referring_domain': query?.referring_domain || null,
      'utm_campaign': query?.utm_campaign || null,
      'utm_content': query?.utm_content || null,
      'utm_medium': query?.utm_medium || null,
      'utm_source': query?.utm_source || null,
      'utm_term': query?.utm_term || null,
    }
  }

  return utmPayload
}

export {
  sendAmplitudeEventsToSegment,
  identifySegment,
  removeItemsKeyword,
  includeAllowedProps,
  getCurrentSegmentSessionID,
  getUtmPayload,
}
