import get from 'lodash/get'
import _isNumber from 'lodash/isNumber'
import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { decodeEntities } from '../../lib/stringHelperFunctions'

const WEIGHTS = {
  regular: 500,
  bold: 700,
  black: 900,
}

const pulsate = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10000px 0;
  }
`

const skeletonMixin = css`
  display: block;
  min-width: 10rem;
  width: 100%;
  height: 1.3rem;
  margin-bottom: 0.1rem;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  animation: ${pulsate} 20s infinite linear;
`

const SIZES = {
  small: { 'font-size': 0.75, 'line-height': 1 }, // 12
  medium: { 'font-size': 0.875, 'line-height': 1.25 }, // 14
  large: { 'font-size': 1, 'line-height': 1.5 }, // 16
  xl: { 'font-size': 1.125, 'line-height': 1.5 }, // 18
  mxl: { 'font-size': 1.25, 'line-height': 1.5 }, // 20
  xxl: { 'font-size': 1.5, 'line-height': 2 }, // 26
}

const LineThrough = styled.span`
  ${({ color }) => color && `color: ${color};`}
  text-decoration: line-through;
  width: 100%;
  height: 100%;
`

const Text = ({ children, isLineThrough, ...props }) => {
  if (isLineThrough) {
    return (
      <LineThrough {...props}>
        <span>{props.isDecodedText ? decodeEntities(children) : children}</span>
      </LineThrough>
    )
  }

  return (
    <span {...props}>
      {props.isDecodedText ? decodeEntities(children) : children}
    </span>
  )
}

const StyledText = styled(Text)`
  font-family: Lato, sans-serif;
  text-align: ${({ align }) => align};
  font-weight: ${({ weight }) => WEIGHTS[weight]};
  color: ${({ color }) => color};

  text-transform: ${({ caps }) => caps && 'uppercase'};

  font-size: ${({ size = 'medium' }) => SIZES[size]['font-size']}rem;
  line-height: ${({ size = 'medium' }) => SIZES[size]['line-height']}rem;

  b,
  strong {
    font-weight: ${({ bWeight }) =>
      bWeight ? WEIGHTS[bWeight] : WEIGHTS['bold']};
  }

  ${({ margin }) => margin && `margin: ${margin};`}

  ${({ children }) => !children && !_isNumber(children) && skeletonMixin}
`

const Placeholder = styled.span`
  ${({ children }) => !children && !_isNumber(children) && skeletonMixin}
`

// Common helper methods
const gather = (obj, ...accessors) =>
  obj === undefined ? undefined : accessors.map(accessor => get(obj, accessor))

export const Repeat = ({ count, children, className }) =>
  Array.from({ length: count }).map((elem, index) => (
    <li className={className} key={index}>
      {children}
    </li>
  ))

Repeat.defaultProps = {
  count: 4,
}

export const DefinedOrPlaceholder = ({
  predicate,
  children,
  placeholder: Placeholder,
  isDecodedText,
}) => {
  if (typeof children !== 'function') {
    return children
  }

  // If `value` is `undefined`, placeholder will be rendered.
  const value = (() => {
    if (typeof predicate === 'function') {
      // Whatever that is returned from `predicate()` will be passed to
      // render props as arguments. It can be anything you desire.
      return predicate({ gather })
    }

    if (Array.isArray(predicate)) {
      if (predicate.length === 0) {
        return undefined
      }

      if (predicate.length === 1) {
        return predicate[0]
      }

      if (predicate.length === 2) {
        return get(...predicate, undefined)
      }
    }

    return undefined
  })()

  return value === undefined
    ? Placeholder
    : children(isDecodedText ? decodeEntities(value) : value)
}

DefinedOrPlaceholder.defaultProps = {
  placeholder: <Placeholder />,
}

export default StyledText
